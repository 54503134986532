@import url(~bootstrap/scss/bootstrap.scss);

body{
  background: linear-gradient(
    to bottom,
    rgba(247, 247, 247, 0.9),
    rgba(247, 247, 247, 0.9)
  ),url(assets/images/bg.jpg) top left repeat;
  background-size: contain;
}
.rounded-4{
  border-radius: 1.2rem !important;
}

.card {
  border-radius: 1.2rem !important;
}